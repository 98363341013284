<!-- This modal is imported into feature-view.vue as a tab. This modal lists all applicable promotions for a specific ad, served by an API call that limits based on date range and responsible party-->
<template>
  <v-container class="ma-0 pa-0" fluid v-resize="onResize" >
    <v-divider/>
    <v-row style="width:100%">
      <v-col class="ml-3 pl-2 mr-0 pr-0 my-3 py-2" cols = "6">
        <SearchFilters v-model="searchParams" :disabled="loading" @search="getPromoList"/>
      </v-col>
      <v-col class="pl-0 my-3 py-2">
        <v-btn
          :loading="loading"
          :disabled="loading"
          depressed
          height="40"
          color="accent"
          style="border-top-left-radius: 0px; border-bottom-left-radius:0px; border: 1px solid #9e9e9e; border-left: 0px;"
          @click.stop="getPromoList">
         <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
      <v-col class="my-auto" align="end">
        <v-btn text depressed class="primary--text" v-if='promoDetail' @click="promoDetail=false">
          <v-icon left>mdi-minus</v-icon>Back to promotions
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table 
      :headers="headers" 
      :height="tableHeight" 
      :items="items"
      loading-text="Searching Available Data...Please Wait"
      :loading="loading"
      class="rounded-0"
      fixed-header
      hide-default-footer
      :items-per-page="pageSize"
      :item-class="itemRowBackground"
      item-key="id"
      loader-height="5"
      no-data-text="No Data to Display"
      dense
      v-if='!promoDetail'>
      <template #[`item.promo_number`]="{ item }">
        <span v-if="item.promo_number">{{item.promo_number}}</span>
        <span v-else>-</span>
      </template>
      <template #[`item.name`]="{ item }">
        <span v-if="item.name">{{item.name}}</span>
        <span v-else>-</span>
      </template>
      <template #[`item.start_dt`]="{ item }">
        <span v-if="item.start_dt">{{item.start_dt}}</span>
        <span v-else>-</span>
      </template>
      <template #[`item.end_dt`]="{ item }">
        <span v-if="item.end_dt">{{item.end_dt}}</span>
        <span v-else>-</span>
      </template>
      <template #[`item.amount_off`]="{ item }">
        <span v-if="item.amount_off">${{formatCurrencyDisplay(item.amount_off)}}</span>
        <span v-else>-</span>
      </template><template #[`item.promotion_price`]="{ item }">
        <span v-if="item.promotion_price">${{formatCurrencyDisplay(item.promotion_price)}}</span>
        <span v-else>-</span>
      </template><template #[`item.rebate_amount`]="{ item }">
        <span v-if="item.rebate_amount">${{formatCurrencyDisplay(item.rebate_amount)}}</span>
        <span v-else>-</span>
      </template><template #[`item.variance`]="{ item }">
        <span v-if="item.variance">${{formatCurrencyDisplay(item.variance)}}</span>
        <span v-else>-</span>
      </template><template #[`item.billback_allowance`]="{ item }">
        <span v-if="item.accrual_amt">${{formatCurrencyDisplay(item.accrual_amt)}}</span>
        <span v-else-if="item.billback_allowance">${{formatCurrencyDisplay(item.billback_allowance)}}</span>
        <span v-else>-</span>
      </template><template #[`item.amap_allowance`]="{ item }">
        <span v-if="item.amap_allowance">${{formatCurrencyDisplay(item.amap_allowance)}}</span>
        <span v-else>-</span>
      </template><template #[`item.early_buy_allowance`]="{ item }">
        <span v-if="item.early_buy_allowance">${{formatCurrencyDisplay(item.early_buy_allowance)}}</span>
        <span v-else>-</span>
      </template>

      <template v-slot:[`item.related_promos`]="{ item }">
        <v-tooltip v-if="item.related_promos" class="mx-auto" top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              small
              color="primary"
              @click.stop="viewRelatedPromos(item)">
              <v-icon class="mx-auto">mdi-view-list</v-icon>
            </v-btn>
          </template>
          <span>View Related Promos</span>
        </v-tooltip>
      </template>

      <template #[`item.actions`]="{ item }">
          <v-btn icon @click.stop="promoDetail= true, selectedPromo=item, getPromoItemsByPromo()">
              <v-icon>mdi-information</v-icon>
          </v-btn>
      </template>
    </v-data-table>
    <v-data-table
      v-else
      :headers="pigheaders"
      :height="tableHeight"
      :items="promoItemGroups"
      loading-text="Searching Available Data...Please Wait"
      :loading="loading"
      class="rounded-0"
      fixed-header
      hide-default-footer
      :items-per-page="pageSize"
      item-key="id"
      loader-height="5"
      no-data-text="No Data to Display"
      group-by="promo_item_group.id">
      <template #[`group.header`]="{ group, items, headers}">
        <td :colspan="headers.length">
          <v-row align="center">
            <v-col class='truncate'>
              <span><b>Promo Item Group</b><br></span>
              <span v-if='items[0].promo_item_group.name'>
                {{items[0].promo_item_group.name}}
              </span>
              <span v-else>N/A</span>
            </v-col>
            <v-col class='truncate'>
              <span>
                <b>Promo Name</b><br>
                {{selectedPromo.name}}
              </span>
            </v-col>
            <v-col>
              <span>
                <b>Promo Category</b><br>
                {{selectedPromo.promo_category_name}}
              </span>
            </v-col>
            <v-col>
              <span>
                <b>Promo Start Date</b><br>
                {{formatDateDisplay(selectedPromo.start_dt)}}
              </span>
            </v-col>
            <v-col>
              <span>
                <b>Promo End Date</b><br>
                {{formatDateDisplay(selectedPromo.end_dt)}}
              </span>
            </v-col>
           <v-col>
              <span>
                <b>Billing Mode</b><br>
                {{selectedPromo.billing_mode_name}}
              </span>
            </v-col>
            <v-col align="right">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn :readonly="feature.promo_item_group_ids.includes(group) " small fab style="height:25px; width:25px;"
                    v-on="on" class="mr-12 white--text"
                    @click="feature.promo_item_group_ids.includes(group) ? '' : linkPromo(group)"
                    :color="feature.promo_item_group_ids.includes(group) ? 'green' : 'primary'">
                    <v-icon small>{{feature.promo_item_group_ids.includes(group) ? 'mdi-check' : 'mdi-plus'}}</v-icon>
                  </v-btn>
                </template>
                <span>{{feature.promo_item_group_ids.includes(group) ? 'Added' : 'Add'}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </td>
      </template>
      <template #[`item.promotion_price`]="{ item }">
        <span v-if="item.promo_item_group.promotion_price">${{formatCurrencyDisplay(item.promo_item_group.promotion_price)}}</span>
        <span v-else-if="selectedPromo.promotion_price">${{formatCurrencyDisplay(selectedPromo.promotion_price)}}</span>
        <span v-else>-</span>
      </template>

      <template #[`item.rebate_amount`]="{ item }">
        <span v-if="item.promo_item_group.rebate_amount">${{formatCurrencyDisplay(item.promo_item_group.rebate_amount)}}</span>
        <span v-else-if="selectedPromo.rebate_amount">${{formatCurrencyDisplay(selectedPromo.rebate_amount)}}</span>
        <span v-else>-</span>
      </template>

      <template #[`item.billback_allowance`]="{ item }">
        <span v-if="item.accrual_amt">${{formatCurrencyDisplay(item.accrual_amt)}}</span>
        <span v-else-if="item.promo_item_group.billback_allowance">${{formatCurrencyDisplay(item.promo_item_group.billback_allowance)}}</span>
        <span v-else-if="selectedPromo.billback_allowance">${{formatCurrencyDisplay(selectedPromo.billback_allowance)}}</span>
        <span v-else>-</span>
      </template>

      <template #[`item.amap_allowance`]="{ item }">
        <span v-if="item.promo_item_group.amap_allowance">${{formatCurrencyDisplay(item.promo_item_group.amap_allowance)}}</span>
        <span v-else-if="selectedPromo.amap_allowance">${{formatCurrencyDisplay(selectedPromo.amap_allowance)}}</span>
        <span v-else>-</span>
      </template>

      <template #[`item.early_buy_allowance`]="{ item }">
        <span v-if="item.promo_item_group.early_buy_allowance">${{formatCurrencyDisplay(item.promo_item_group.early_buy_allowance)}}</span>
        <span v-else-if="selectedPromo.early_buy_allowance">${{formatCurrencyDisplay(selectedPromo.early_buy_allowance)}}</span>
        <span v-else>-</span>
      </template>

      <template #[`item.item.sale_price`]="{ item }">
        <span v-if="item.item.sale_price">${{formatCurrencyDisplay(item.item.sale_price)}}</span>
        <span v-else>-</span>
      </template>

      <template #[`item.item.cost`]="{ item }">
        <span v-if="item.item.cost">${{formatCurrencyDisplay(item.item.cost)}}</span>
        <span v-else>-</span>
      </template>
      <template #[`item.item.unit_cost`]="{ item }">
          <span v-if="item.item.qty_per_case">  ${{formatCurrencyDisplay(calcUnitCost(item))}} </span>
          <span v-else> - </span>
        </template>
    </v-data-table>
    <v-toolbar dense flat color="#fafafa">
      <v-row align="center">
        <v-col cols='9'>
        </v-col>
        <v-col cols="2" style="max-width:150px;" justify='end'>
          <v-select class="mt-8" dense :items="pageSizes" label="Items Per Page" select v-model="pageSize"
            :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
          </v-select>
        </v-col>
        <v-btn v-if='!promoDetail' icon large @click="previous()" :disabled="disablePrevious" class="mx-2 ">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <small v-if='!promoDetail'>Page {{page}}</small>
        <v-btn v-if='!promoDetail' icon large @click="next()" :disabled="disableNext" class="mx-2">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn v-if='promoDetail' icon large @click="previousPIG()" :disabled="disablePreviousPIG" class="mx-2 ">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <small v-if='promoDetail'>Page {{pigpage}}</small>
        <v-btn v-if='promoDetail' icon large @click="nextPIG()" :disabled="disableNextPIG" class="mx-2">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-row>
    </v-toolbar>
    <RelatedPromos
      v-if="relatedPromosDialog"
      v-model="relatedPromosDialog"
      :promo="currentPromo"
      :related_promos="JSON.parse(currentPromo.related_promos)"
      :groupIds="feature.promo_item_group_ids"
      @addPromoItemGroups="addPromoItemGroups"
      @close="closeRelatedPromos">
    </RelatedPromos>
  </v-container>
</template>
<script>
import Search from '@/axios/search-endpoint'
import SearchFilters from '@/components/features/promotions/SearchFilters.vue'
const RelatedPromos = () => import('@/components/features/promotions/RelatedPromos.vue')
import { displayHelpers } from '@/mixins/display-helpers'
import { displayAlert } from '@/mixins/alert'
import { format } from '@/mixins/format'
import { utils } from '@/mixins/utils'
export default {
  name: 'PromotionList',
  mixins: [displayHelpers, displayAlert, format, utils],
  components: { SearchFilters, RelatedPromos },
  props: [
    'feature',
    'selectedAd',
    'showInfo'
  ],
  data: () => ({
    relatedPromosDialog: false,
    currentPromo: null,
    store_group_id: '',
    dc_id:'',
    loading: true,
    modal: false,
    itemSearch: null,
    promoInfo: false,
    items: [],
    promoDetail: false,
    promoItemGroups:[],
    responsible_party_id: '',
    searchParams: {},
    selectedGroup: '',
    selectedPromo:{},
    party_info: {},
    toggle: null,
    tableHeight: '',
    offset: true,
    page: 1,
    pigpage: 1,
    pageSize: 50,
    pageSizes: [50, 100]
  }),
  computed: {
    disablePrevious () {
      return ((this.page - 1) * this.pageSize) === 0
    },
    disableNext () {
      return this.items.length < this.pageSize
    },
    disablePreviousPIG () {
      return ((this.pigpage - 1) * this.pageSize) === 0
    },
    disableNextPIG () {
      return this.promoItemGroups.length < this.pageSize
    },
    headers () {
        return [
          { sortable: false, filterable: false, class: 'accent white--text', show: true },
          { text: 'Promotion Number', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'promo_number', show: true },
          { text: 'Promotion Name', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'name', show: true },
          { text: 'Promotion Category', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'promo_category_name', show: true },
          { text: 'Category Manager', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'category_manager', show: this.$auth.tenant === 'pricechopper' },
          { text: 'Vendor', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'vendors', show: true, width: 300 },
          { text: 'Promotion Type', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'billing_mode_name', show: true },
          { text: 'Start Date', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'start_dt', show: true },
          { text: 'End Date', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'end_dt', show: true },
          { text: 'Ad Date', groupable: false, align: 'center', sortable: true, class: 'accent white--text', value: 'ad_date', show: this.$auth.tenant === 'alliance-retail-group' },
          { text: 'Related Promos', groupable: false, align: 'center', sortable: false, class: 'accent white--text', value: 'related_promos', show: true },
          { text: 'Item Groups', groupable: false, align: 'center', sortable: false, class: 'accent white--text', value: 'actions', show: true },
        ].filter(header => header.show)
    },
    pigheaders () {
      return [
         { text: 'Item ID', groupable: false, align: 'center', class: 'accent white--text', sortable: false, value: 'item.item_id' },
         { text: 'Source', groupable: false, align: 'center', sortable: false, class: 'accent white--text', value: 'item.source' },
         { text: 'Name', groupable: false, align: 'center', sortable: false, class: 'accent white--text', value: 'item.description' },
         { text: 'Promotion Price', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'promotion_price' },
         { text: 'Scan Allowance', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'rebate_amount' },
         { text: 'Billback Allowance', groupable: false, align: 'center', sortable: false, class: 'accent white--text', value: 'billback_allowance' },
         { text: 'AMAP Allowance', groupable: false , align: 'center', sortable: false, class: 'accent white--text', value: 'amap_allowance' },
         { text: 'Early Buy Allowance', groupable: false, align: 'center', sortable: false, class: 'accent white--text', value: 'early_buy_allowance' },
         { text: 'Retail Price', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.sale_price' },
         { text: 'Case Cost', groupable: false, align: 'center', sortable: false, class: 'accent white--text', value: 'item.cost' },
         { text: 'Net Unit Cost', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.unit_cost' },
         { text: 'Pack Size', groupable: false, align: 'start', sortable: false, class: 'accent white--text', value: 'item.pack_size' },
      ]
    },
    piheaders () {
      return[
        { sortable: false, filterable: false },
        { text: 'Promotion Number', groupable: false, align: 'center', sortable: true, value: 'promo_item_id' },
      ]
    },
    responsibleParty () {
      return this.$store.getters.responsibleParties.find(party => party.tenant === this.$auth.tenant)
    },
    classification () {
      return this.$store.getters.classifications.find(c => c.id === this.selectedAd.classification).constant
    }
  },
  watch: {
    searchParams: {
      deep: true,
      handler (newValue) {
        if (newValue) {
          if (this.page > 1) {
            this.page = 1
          }
        }
      }
    },
    showInfo: {
      immediate: true,
      handler () {
        if (!this.showInfo) {
          this.tableHeight = window.innerHeight - 300
        } else {
          this.tableHeight = window.innerHeight - 365
        }
      }
    },
  },
  async created () {
    if (!this.showInfo) {
      this.tableHeight = window.innerHeight - 300
    } else {
      this.tableHeight = window.innerHeight - 365
    }
    this.store_group_id = this.selectedAd.ad_party_id
    this.party_info = JSON.parse(sessionStorage.getItem('ad_party'))
    this.dc_id = this.party_info.dc_id
    this.responsible_party_id = this.responsibleParty.id
    await this.getPromoList()
  },
  methods: {
    viewRelatedPromos(promo) {
      this.currentPromo = promo
      this.relatedPromosDialog = true
    },
    closeRelatedPromos() {
      this.relatedPromosDialog = false
      this.currentPromo = null
    },
    onResize () {
      this.tableHeight = window.innerHeight - 365
    },
    itemRowBackground (item) {
      return this.items.indexOf(item) % 2 !== 1 ? 'grey lighten-2' : ''
    },
    async update (actions) {
      await this.$Features.updateAdFeature(this.feature.id, this.feature)
        .then(() => {
          this.$root.$emit('get-items')
          this.$store.dispatch('setSnackbar', { status: 'success', text: `Promotion Item Group ${actions}` })
        }).catch(err => {
          this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Update Feature due to ${err?.response?.data?.message || err.message}` })
        })
    },
    async getPromoList() {
      this.loading = true
      this.$store.dispatch('setSnackbar', { status: 'error', text: `Loading Promotions...` })
      let promos = []
      try {
        const params = this.buildPromoSearchParams()
        const { data } = await this.$Promos.getPromoList(params, this.searchParams, this.responsible_party_id)
        if (data?.length > 0) {
          promos = data
        }
        this.items = promos
        if (this.items.length > 0) {
          await this.getPromoPartyData()
        }
        this.$store.dispatch('setSnackbar', { status: 'success', text: `Promotions Loaded!` })
      } catch (err) {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Promotions due to ${err?.response?.data?.message || err.message}` })
      } finally {
        this.loading = false
      }
    },
    buildPromoSearchParams() {
      const limit = this.pageSize
      const offset = (this.page - 1) * this.pageSize
      let params = {
        limit: limit, offset: offset, start_dt: this.selectedAd.ad_start_date, end_dt: this.selectedAd.ad_end_date, 
        store_group_id: this.store_group_id, party_id: this.selectedAd.ad_party_id, source: this.party_info.distribution_center, include_related: true
      }
      if (this.$auth.tenant === 'pricechopper') {
        params['ad_id'] = this.selectedAd.id
      }
      return params
    },
    async getPromoItemsByPromo() {
      this.loading = true
      const limit = this.pageSize
      const offset = (this.pigpage - 1) * this.pageSize
      const params = { limit, offset }
      const source = this.party_info.distribution_center
      let groups = []
      try {
        const { data } = await this.$PromoItem.getItemsByPromo(this.selectedPromo.id, params)
        if (data?.length > 0) {
          groups = data.filter(item => item.item.source === source)
        }
        this.promoItemGroups = groups
      } catch (err) {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Failed to Get Promotion Items due to ${err?.response?.data?.message || err.message}` })
      } finally {
        this.loading = false
      }
    },
    addPromoItemGroups(groupIds) {
      this.feature.promo_item_group_ids.push(...groupIds)
      this.relatedPromosDialog = false
      this.update('Added')
    },
    async getPromoPartyData() {
      let party_ids = []
      let parties = []
      this.items.forEach(item => {
        if (item.vendor_party_ids?.length > 0) {
          party_ids = party_ids.concat(item.vendor_party_ids)
        }
        if (item.category_manager_id) {
          party_ids.push(item.category_manager_id)
        }
      })
      if (party_ids.length > 0) {
        parties = await this.searchParties(party_ids)
        this.items.forEach(item => {
          if (item.category_manager_id) {
            item.category_manager = parties.find(p => p.party_id === item.category_manager_id).party_name
          }
          if (item.vendor_party_ids?.length > 0) {
            let vendors = ''
            item.vendor_party_ids.forEach(vendor_party_id => {
              const vendor = parties.find(p => p.party_id === vendor_party_id)
              vendors = vendors ===  '' ? `${vendor.attributes.VENDOR_ID} ${vendor.party_name}` : vendors.concat(', ', `${vendor.attributes.VENDOR_ID} ${vendor.party_name}`)
            })
            item.vendors = vendors
          }
        })
      }
    },
    async searchParties (party_ids) {
      const searchTerms = this.putPartyIdsInGroups(party_ids)
      const data = await this.batchIdSearch(searchTerms, 'or')
      return data
    },
    putPartyIdsInGroups (idList) {
      const maxSearchSize = 250   // Will fail if above 275
      let searchTerms = new Array()

      // If just passing in one string value (usually a default value), then return an array of just that value
      if (typeof(idList) == "string")
        return [idList]

      // Else, break up the array into smaller batches and pass that back
      let groups = Math.ceil(idList.length / maxSearchSize)
      for (let x = 0; x < groups; x++)
      {
        let startIndex = x * maxSearchSize
        let endIndex = (x + 1) * maxSearchSize
        searchTerms.push(idList.slice(startIndex, endIndex).join(' '))
      }

      return searchTerms
    },
    async batchIdSearch (searchTerms, operator = "or") {
      try {
        const searches = searchTerms.map(searchTerm => {
            return Search.customerSearch(searchTerm, operator)
        })

        const { fulfilled, rejected } = await this.getAllSettled(searches, true)
        if (rejected.length > 0)
          throw rejected

        const results = fulfilled.flatMap(result => result.data)
        return results
      }
      catch (err) {
        throw err[0].reason
      }
    },
    linkPromo(id) {
      this.feature.promo_item_group_ids.push(id)
      this.update('Added')
    },
    unlinkPromo(id){
      this.feature.promo_item_group_ids = this._.pull(this.feature.promo_item_group_ids, id)
      this.update('Removed')
    },
    previous () {
      this.page = this.page - 1
      if (this.page < 1) {
        this.page = 1
      }
      this.getPromoList()
    },
    next () {
      this.page = this.page + 1
      this.getPromoList()
    },
    previousPIG () {
      this.pigpage = this.pigpage - 1
      if (this.pigpage < 1) {
        this.pigpage = 1
      }
      this.getPromoItemsByPromo()
    },
    nextPIG () {
      this.pigpage = this.pigpage + 1
      this.getPromoItemsByPromo()
    },
    calcUnitCost(item) {
      if (item.accrual_amt) {
        return ((item.item.cost -(item.promo_item_group.rebate_amount+item.accrual_amt+item.promo_item_group.amap_allowance+item.promo_item_group.early_buy_allowance))/item.item.qty_per_case)
      }
      if (this.selectedPromo.promo_type_constant === 'BILLBACK') {
        return ((item.item.cost -(item.promo_item_group.rebate_amount+item.promo_item_group.billback_allowance+item.promo_item_group.amap_allowance+item.promo_item_group.early_buy_allowance))/item.item.qty_per_case)
      } else {
        return (item.item.cost/item.item.qty_per_case-(item.promo_item_group.rebate_amount+item.promo_item_group.billback_allowance+item.promo_item_group.amap_allowance+item.promo_item_group.early_buy_allowance))
      }
    }
  }
}
</script>
<style>
  .active {
    box-shadow: 0px 2px 10px 3px rgba(120,120,120,0.75);
    background: #385F73;
    color: white !important;
  }
  .truncate {
    display:inline;
    width:20px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis
  }
  .theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: #fff !important;
  }
</style>