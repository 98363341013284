<template>
  <v-container fluid class="ma-0 pa-0">
    <v-autocomplete
      :value="selectedSubvendors"
      :items="subvendors"
      item-text="name"
      item-value="subvendor_party_id"
      label="Subvendors"
      :loading="loading"
      :search-input.sync="subvendorSearch"
      background-color="input"
      :placeholder="isAdmin ? 'Start typing to search' : null"
      hide-no-data
      hide-details
      multiple
      cache-items
      return-object
      small-chips
      deletable-chips
      dense
      outlined
      @change="$emit('update:selectedSubvendors', $event)">
      <template v-slot:item="{ item, attrs, on }">
        <v-list-item v-bind="attrs" color="primary" v-on="on">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.party_type_name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </v-container>
</template>

<script>
import Search from '@/axios/search-endpoint'
// mixins
import { userAccess } from '@/mixins/user-access'
import { displayAlert } from '@/mixins/alert'
// third-party
import { debounce } from 'lodash'

export default {
  data() {
    return {
      subvendors: [],
      subvendorSearch: '',
      loading: false
    }
  },
  name: 'SubvendorSelect',
  mixins: [userAccess, displayAlert],
  props: {
    selectedSubvendors: Array
  },
  watch: {
    subvendorSearch: {
      handler: debounce(function (newValue) {
        if (this.searchEnabled && newValue) {
          this.searchSubvendors()
        }
      }, 500)
    },
    selectedSubvendors: {
      handler(newValue) {
        if (newValue && newValue.length > 0) {
          this.initSubvendors()
        }
      },
      deep: true
    },
    userRelatedParties: {
      handler(newValue) {
        if (newValue && newValue.length > 0 && this.limitAccessByRelatedSubvendors) {
          this.subvendors.push(...this.userDropdownParties)
        }
      },
      deep: true
    }
  },
  created() {
    if (this.selectedSubvendors.length > 0) {
      this.initSubvendors()
    }
    if (this.limitAccessByRelatedSubvendors && this.userRelatedParties.length > 0) {
      this.subvendors.push(...this.userDropdownParties)
    }
  },
  computed: {
    userDropdownParties() {
      return this.relatedSubvendorParties.flatMap(subvendor => this.formatParty(subvendor))
    },
    searchEnabled() {
      return !this.limitAccessByRelatedSubvendors
    }
  },
  methods: {
    initSubvendors() {
      this.subvendors = [...this.selectedSubvendors]
    },
    async searchSubvendors() {
      if (this.limitAccessByRelatedSubvendors && this.relatedSubvendorParties.length === 0) return
      if (this.subvendorSearch) this.loading = true
      const term = this.subvendorSearch || ''
      let subvendors = []
      try {
        const res = await Search.customerSearch(term, 'and')
        if (res && res.data && res.data.length > 0) {
          subvendors = res.data.flatMap(party => {
            return (party.party_type) === 'SUBVENDOR'
              ? this.formatParty(party)
              : []
          })
        }
        this.subvendors = [...this.subvendors, ...subvendors]
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    formatParty(party) {
      let display_name = party.party_name || party.name
      const partyType = party.party_type_constant || party.party_type
      party.name = display_name
      party.party_type_name = this.formatPartyType(partyType)
      if (!party.id) party.id = party.party_id
      return party
    },
    formatPartyType(str) {
      str = str.toLowerCase().replaceAll('_', ' ')
      const parts = str.split(' ').map(part => part.charAt(0).toUpperCase() + part.slice(1))
      return parts.join(' ')
    }
  }
}
</script>
